//// ***********************************
//// Exclusively contains all the styles for the component
//// TeamMemberActivity.tsx in this folder
//// ***********************************

.activity {
  padding-left: 5px;
  padding-right: 5px;
  min-width: 0;
  padding-top: 2px;
  padding-bottom: 4px;

  &.inactive {
    padding-top: 10px;
    padding-bottom: 10px;
    white-space: nowrap;
    text-align: center;
    font-size: 1.25rem;
  }

  .top {
    padding-left: 1px;
    padding-right: 1px;
    display: flex;
    justify-content: space-between;

    .type {
      font-size: .65rem;
    }

    .id {
      font-size: 1.1rem;
      font-weight: 450;
      letter-spacing: 0.9px;
    }
  }

  .title {
    //line-height: 0.9;
    white-space: nowrap;
    text-align: center;
    font-size: 1.45rem;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 4px;
    font-weight: 500;
  }

  .time {
    white-space: nowrap;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.2rem;
    padding-bottom: 2px;
    font-weight: 500;
  }

}
