//// ***********************************
//// Exclusively contains all the styles for the component
//// TeamMember.tsx in this folder
//// ***********************************

.container {
  background-color:#a7a9ab;
  padding: 2px;
  min-width: 0;

  &.hasAnyActivityToday {
  }

  &:not(.hasAnyActivityToday) {
    order: 900;

    .top {
      background-color: white;
      // dull out any user not active today
      opacity: 0.65;
    }
  }
  .wrapper {
    background-color: white;
    height: 100%;
    width: 100%;
    min-width: 0;


    display: grid;
    justify-content: stretch;
    justify-items: stretch;
    align-items: stretch;
    align-content: stretch;
    grid-template-rows: min-content auto;

    .top {
      background-color: white;
      display: flex;
      flex-wrap: nowrap;
      width: 100%;
      justify-content: center;
      min-width: 0;
      border-bottom-color: #a7a9ab;
      border-bottom-style: solid;
      border-bottom-width: 1px;

      .userId {
        display: none;
        padding-top: 2px;
        font-size: 0.45rem;

      }
    }
  }
}
