//// ***********************************
//// Exclusively contains all the styles for the component
//// StatsBox.tsx in this folder
//// ***********************************

.container {
  background-color: white;

  flex-grow: 2;

  .count {
    text-align: center;
    font-weight: 700;
    font-size: 1.85em;
    padding-left: 5px;
    padding-right: 5px;
  }

  .title {
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

}
