//// ***********************************
//// Exclusively contains all the styles for the component
//// WallboardTop.tsx in this folder
//// ***********************************

.container {
  display: flex;
  flex-wrap: nowrap;

  column-gap: 25px;
  padding-right: 10px;
  padding-left: 10px;

  .serviceBoards {
    flex-grow: 2;
  }
  .companyStats {
  }

}
