


.container {
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 7px;
  padding-right: 7px;

  background-color: rgba(167, 169, 171, 0.3);

  .paper {
    padding-top: 0;
    padding-bottom: 5px;
    padding-left: 7px;
    padding-right: 7px;

    .sectionTitle {
      font-weight: 700;
      font-size: 1.3em;
      padding-bottom: 5px;
      text-align: center;
      margin-bottom: 5px;

    }

    .items {

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
      column-gap: 15px;


    }
  }
}