//// ***********************************
//// Exclusively contains all the styles for the component
//// TeamMemberDayTimeline.tsx in this folder
//// ***********************************

.container {
  height: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  align-content: stretch;
  margin-bottom: 1px;


  border-width: 1px;
  border-style: solid;
  border-color: #a7a9ab;
}
