.item {
  ////flex: 0 0 20%;
  //display: flex;
  //flex-direction: row;
  //flex-wrap: nowrap;
  //justify-content: space-between;
  //align-items: stretch;
  //align-content: stretch;
  //padding-right: 15px;
  //padding-left: 5px;

  flex-grow: 1;

  // setup so all cards are the same width
  flex-basis: 1px;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  &:nth-child(even) {
    //background-color: lightgray;
  }

  .title {

    padding-left: 3px;
    padding-right: 3px;
    padding-top: 3px;
    padding-bottom: 3px;
    text-align: center;
    font-size: 1.2em;
    font-weight: 600;

    white-space: nowrap;
  }

  .content {
    padding-top: 10px;

    &:last-child {
      padding-bottom: unset;
    }

    .percent {
      text-align: center;
      white-space: nowrap;
      font-size: 1.8em;
      margin-bottom: 5px;
    }
    .value {
      text-align: center;
      white-space: nowrap;
      padding-bottom: 3px;
      font-size: 0.8em;
    }
  }
}