//// ***********************************
//// Exclusively contains all the styles for the component
//// TeamMemberName.tsx in this folder
//// ***********************************

.name {
  min-width: 0;
  padding-top: 3px;
  padding-left: 3px;
  padding-right: 3px;
  padding-bottom: 2px;
  text-align: center;
  color:#034ea1;
  font-weight: 600;
  font-size: 1.6rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}
