//// ***********************************
//// Exclusively contains all the styles for the component
//// TeamMembers.tsx in this folder
//// ***********************************

@mixin themeGridColumns($numberCol: 4) {
  display: grid;
  grid-template-columns: repeat($numberCol, 1fr);
  column-gap: 3px;
  row-gap: 2px;
  justify-items: stretch;
  align-items: stretch;
  justify-content: space-between;
  align-content: start;
  min-width: 0;
  min-height: 0;
  > * {
    min-width: 0;
  }
}

.container {
  flex-grow: 3;
  max-width: 100vw;
  overflow: hidden;
  padding: 2px;

  &.theme_default,
  &.theme_4col {
    @include themeGridColumns(4);
  }
  &.theme_2col {
    @include themeGridColumns(2);
  }
  &.theme_3col {
    @include themeGridColumns(3);
  }
  &.theme_5col {
    @include themeGridColumns(5);
  }
  &.theme_6col {
    @include themeGridColumns(6);
  }

}
