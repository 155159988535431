//// ***********************************
//// Exclusively contains all the styles for the component
//// Logo.tsx in this folder
//// ***********************************

.container {
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 8px;
  margin-bottom: 8px;

  background-color: white;

  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  .logo {

    height: auto;
    width: 90%;
    max-width: 200px;
    max-height: 100px;
  }

}
