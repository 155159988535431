//// ***********************************
//// Exclusively contains all the styles for the component
//// ScreenStatsGroup.tsx in this folder
//// ***********************************

.container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  gap: 5px;
  padding: 3px;
  background-color: rgb(167, 169, 171);
}
