//// ***********************************
//// Exclusively contains all the styles for the component
//// TeamMemberDayTimelineEntry.tsx in this folder
//// ***********************************

.container {
  flex-grow: 1;
  flex-shrink: 1;
  background-color: #78f75e;

  //opacity: 0.8;
  //
  &:not(:first-of-type) {
    //border-left: 1px solid #a7a9ab;
    border-left: 1px solid black;
  }
  //border-color: black;
  //border-style: solid;
  //border-width: 0.5px;
}
