//// ***********************************
//// Exclusively contains all the styles for the component
//// TimeAgo.tsx in this folder
//// ***********************************

.ago {
  font-size: 0.9rem;
}

.dayTotal {
  font-size: 0.7rem;
  padding-bottom: 5px;

  .value {
    font-weight: 800;
  }
}
