//// ***********************************
//// Exclusively contains all the styles for the component
//// WallboardPage.tsx in this folder
//// ***********************************

.container {
  margin: 0;
  top: 0;
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;

  :global {
    &.theme_default {

    }
    &.theme_4col {

    }
    &.theme_5col {

    }
    &.theme_6col {

    }
  }
}
