//// ***********************************
//// Exclusively contains all the styles for the component
//// ServiceBoardsList.tsx in this folder
//// ***********************************

.container {
  flex-grow: 2;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  padding-top: 10px;
  padding-bottom: 5px;

  column-gap: 15px;
}
