//// ***********************************
//// Exclusively contains all the styles for the component
//// ServiceBoardCard.tsx in this folder
//// ***********************************

.container {
  flex-grow: 1;

  // setup so all cards are the same width
  flex-basis: 1px;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;


  .header {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
    font-size: 1.2em;
    font-weight: 600;

    white-space: nowrap;

  }

  .content {
    flex-grow: 1;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;

    align-items: stretch;


    &:last-child {
      padding-bottom: unset;
    }
    .availableOverTotalWrapper {
      flex-grow: 2;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .availableOverTotal {
        text-align: center;
        font-size: 2.2em;

      }
    }
    .availablePriority {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      %_priorityBox {
        flex-grow: 2;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 3px;
        padding-right: 3px;
        text-align: center;
        font-weight: 500;
        color: white;
      }

      .critical {
        @extend %_priorityBox;
        background-color: rgb(255, 1, 0);
      }

      .high {
        @extend %_priorityBox;
        background-color: rgb(255, 153, 2);
      }

      .medium {
        @extend %_priorityBox;
        background-color: rgb(254, 255, 0);
        color: black;
      }

      .low {
        @extend %_priorityBox;
        background-color: rgb(1, 0, 255);
      }

    }

  }

}
